import React, { useState } from "react"
// Icon library
import { FaChevronDown, FaChevronUp, FaQuestionCircle } from "react-icons/fa"

export default function FAQSection() {
    const [openIndex, setOpenIndex] = useState<number | null>(null)

    const faqs = [
        {
            question: "What is the AI Interview Question Generator?",
            answer: "It’s an AI-powered tool for job seekers to instantly generate customized, high-quality practice interview questions tailored to roles, companies, and seniority levels.",
        },
        {
            question: "How do I customize the questions for my role?",
            answer: "Select a job title, choose your seniority level, pick a question type, and add the job description. Our tool will generate 10 questions based on your inputs.",
        },
        {
            question: "Is it free to use or do I need a subscription?",
            answer: "You can use the core features completely free. No subscription is required to generate multiple sets of questions.",
        },
        {
            question:
                "Can I use it for both technical and behavioral interviews?",
            answer: "Yes! Choose from various question types like Technical, Behavioral, System Design, Leadership, and more to cover all aspects of your prep.",
        },
        {
            question: "How many questions can I generate at once?",
            answer: "Each generation gives you 10 unique questions. You can re-run the generator anytime for new sets.",
        },
        {
            question: "What if I need help or run into issues?",
            answer: "Our support team is here for you. Reach out anytime via the contact form on our website for assistance.",
        },
    ]

    const toggleFAQ = (index: number) => {
        setOpenIndex(openIndex === index ? null : index)
    }

    return (
        <div style={styles.section}>
            <div style={styles.contentWrapper}>
                <h2 style={styles.title}>Frequently Asked Questions</h2>
                <p style={styles.subtitle}>
                    Everything you need to know about our AI Interview Question
                    Generator
                </p>

                <div style={styles.faqGrid}>
                    {faqs.map((faq, index) => (
                        <div key={index} style={styles.card}>
                            <div
                                style={styles.cardHeader}
                                onClick={() => toggleFAQ(index)}
                            >
                                <div style={styles.iconTitle}>
                                    <FaQuestionCircle
                                        color="#4F46E5"
                                        style={{ marginRight: "8px" }}
                                    />
                                    <span style={styles.questionText}>
                                        {faq.question}
                                    </span>
                                </div>
                                {openIndex === index ? (
                                    <FaChevronUp color="#4F46E5" />
                                ) : (
                                    <FaChevronDown color="#4F46E5" />
                                )}
                            </div>

                            {openIndex === index && (
                                <p style={styles.answer}>{faq.answer}</p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = {
    section: {
        backgroundColor: "#fff",
        padding: "60px 20px",
        fontFamily:
            "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
    },
    contentWrapper: {
        maxWidth: "800px",
        margin: "0 auto",
    },
    title: {
        textAlign: "center",
        fontSize: "32px",
        fontWeight: 700,
        color: "#4F46E5",
        marginBottom: "8px",
    },
    subtitle: {
        textAlign: "center",
        fontSize: "16px",
        color: "#666",
        marginBottom: "40px",
    },
    faqGrid: {
        display: "grid",
        gap: "16px",
    },
    card: {
        backgroundColor: "#f9f9f9",
        borderRadius: "12px",
        padding: "16px 20px",
        boxShadow: "0 2px 6px rgba(0,0,0,0.05)",
        cursor: "pointer",
        transition: "all 0.3s ease",
    },
    cardHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    iconTitle: {
        display: "flex",
        alignItems: "center",
        fontWeight: 600,
        fontSize: "16px",
        color: "#333",
    },
    questionText: {
        flex: 1,
    },
    answer: {
        marginTop: "12px",
        color: "#444",
        fontSize: "15px",
        lineHeight: 1.6,
    },
}
